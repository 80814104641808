import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatPrefix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';

import { filter, take } from 'rxjs/operators';

import { RootFacade } from '../../../state/root/root.facade';
import { SignupStatus } from '../../../state/root/root.reducer';
import { AppTrackerService } from '../../tracker/tracker.service';
import { IMemberTraits, MemberService } from '../member.service';

export interface ISubscriberTraitsForm {
  email: FormControl<string>;
  phone?: FormControl<string>;
}

@Component({
  selector: 'app-newsletter-form',
  standalone: true,
  styleUrls: ['./newsletter-form.component.scss'],
  templateUrl: './newsletter-form.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    RouterLink,
    ReactiveFormsModule,

    MatButton,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatProgressSpinner,
    MatPrefix,
  ],
  host: {
    class: 'app-newsletter-form',
    'attr.color': 'color || null',
  },
})
export class AppSharedNewsletterFormComponent {
  private rootFacade = inject(RootFacade);
  private tracker = inject(AppTrackerService);
  private memberService = inject(MemberService);

  savingMember = this.memberService.loading;

  @Input() color: 'green' | undefined;

  @Output() readonly completed = new EventEmitter<void>();

  form = new FormGroup<ISubscriberTraitsForm>({
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', []),
  });

  constructor() {
    this.listenForEmailInput();
  }

  submit() {
    if (!this.form.valid) {
      this.form.get('accept').markAsTouched();
      return;
    }

    // Update Signup status
    this.rootFacade.setSignupStatus(SignupStatus.PENDING);

    const formValues = this.form.value;

    const userTraits: IMemberTraits = {
      email: formValues.email,
      phone: formValues.phone || null,
    };

    this.memberService.subscribe(userTraits).then(() => {
      this.completed.next();

      this.memberService.openWelcomeDialog();

      this.rootFacade.setSignupStatus(SignupStatus.COMPLETED);
    });
  }

  private listenForEmailInput() {
    this.form
      .get('email')
      .valueChanges.pipe(
        takeUntilDestroyed(),
        filter((value) => value.includes('@')),
        take(1),
      )
      .subscribe(() => {
        console.log('Started');
        this.rootFacade.setSignupStatus(SignupStatus.STARTED);

        this.tracker.track('User Registration Started');
      });
  }
}
