<!--
  ~ Copyright (C) Vinbörsen AB - All Rights Reserved
  ~
  ~ Proprietary and confidential. This source code is protected under
  ~ international copyright law. Unauthorized copying of this file, via any
  ~ medium is strictly prohibited.
  -->

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-form-field style="width: 100%; margin: 0;">
    <mat-label>E-post</mat-label>
    <input
      type="email"
      matInput
      formControlName="email"
      cdkFocusInitial
      email
      required
    />
  </mat-form-field>

  <mat-form-field style="width: 100%; margin: 0;">
    <mat-label>Mobilnummer</mat-label>
    <input matInput type="tel" formControlName="phone" />
    <span matTextPrefix>+46&nbsp;</span>
  </mat-form-field>

  <p size="xsmall" style="margin-top: 0;">
    Genom att registrera dig som prenumerant på Vinbörsens tjänster
    accepterar du Vinbörsens
    <a [routerLink]="['/om-oss', 'allmanna-villkor']">allmänna villkor</a>.
    Dina angivna uppgifter hanteras enligt Vinbörsens
    <a [routerLink]="['/om-oss', 'privacy-policy']">Integritetspolicy</a>.
  </p>

  <button mat-flat-button
          color="primary"
          type="submit"
          [disabled]="!form.valid || savingMember()"
          [class.loading]="savingMember()">
    @if (savingMember()) {
      <mat-progress-spinner diameter="24" mode="indeterminate" strokeWidth="2" />
    }

    Starta prenumeration
  </button>
</form>
